
export const serverAppConfig = {
  "storeType": "B2C",
  "storeDeliveries": [
    {
      "id": "62f22c30a92edf5ce162d3a5",
      "name": "Delivery 1",
      "label": "1-3 dagar",
      "buyable": true
    },
    {
      "id": "62f22c4fa92edf5ce162d3a6",
      "name": "Delivery 2",
      "label": "3-5 dagar",
      "buyable": true
    },
    {
      "id": "62f22c61a92edf5ce162d3a7",
      "name": "Delivery 3",
      "label": "5-8 dagar",
      "buyable": true
    },
    {
      "id": "62f22c76a92edf5ce162d3a8",
      "name": "Delivery 4",
      "label": "1-2 veckor",
      "buyable": true
    },
    {
      "id": "62f22c84a92edf5ce162d3a9",
      "name": "Delivery 5",
      "label": "2-4 veckor",
      "buyable": true
    },
    {
      "id": "62f22c95a92edf5ce162d3aa",
      "name": "Delivery 6",
      "label": "4-6 veckor",
      "buyable": true
    },
    {
      "id": "62f22ca1a92edf5ce162d3ab",
      "name": "Delivery 7",
      "label": "6-8 veckor",
      "buyable": true
    },
    {
      "id": "62f22caea92edf5ce162d3ac",
      "name": "Delivery 8",
      "label": "8-10 veckor",
      "buyable": true
    },
    {
      "id": "62f22cc9a92edf5ce162d3ad",
      "name": "Delivery 9",
      "label": "Obestämd tid",
      "buyable": true
    },
    {
      "id": "62f22cd4a92edf5ce162d3ae",
      "name": "Delivery 10",
      "label": "Ej i lager",
      "buyable": true
    }
  ],
  "language": "sv",
  "paths": {
    "brandsUrlPath": "/varumarken",
    "categoriesUrlPath": "/categories",
    "tagsUrlPath": "/t",
    "productsUrlPath": "/p",
    "blogsUrlPath": "/blog"
  },
  "staticLinks": [
    {
      "id": "brands",
      "label": "Varumärken",
      "path": "/varumarken"
    },
    {
      "id": "blog",
      "label": "Blogg",
      "path": "/blog"
    },
    {
      "id": "minasidor",
      "label": "Mina sidor",
      "path": "/account"
    }
  ],
  "logoUrl": "https://storage.googleapis.com/gecko-media/EC_1033/tags/vinlagring_svart.webp",
  "showCart": true,
  "sortingOptions": [
    {
      "sortBy": "price",
      "sortOrder": "DESC",
      "sortLabel": "Pris högt-lågt",
      "default": false
    },
    {
      "sortBy": "price",
      "sortOrder": "ASC",
      "sortLabel": "Pris lågt-högt",
      "default": true
    },
    {
      "sortBy": "created",
      "sortOrder": "DESC",
      "sortLabel": "Nyaste",
      "default": false
    }
  ],
  "desktopItemHeight": "25vw",
  "mobileItemHeight": "75vw",
  "sliderDefaultValues": [
    0,
    200000
  ],
  "freeShippingOver": 999,
  "shippingProviderConfig": {
    "provider": "INGRID",
    "simpleShipping": {
      "minimumOrderAmountForFreeDelivery": 999,
      "deliveryFee": 139
    },
    "ingridOptions": null
  },
  "paymentProviderConfig": {
    "providerName": "KLARNA",
    "providerOptions": {
      "klarna": {
        "purchaseCountry": "SE",
        "purchaseCurrency": "SEK",
        "phoneMandatory": true,
        "colorButton": null
      }
    }
  },
  "filtersBeforeShowMore": 3,
  "sliderMaxPrice": 200000,
  "topNavHeight": {
    "panel": {
      "base": 0,
      "sm": "5vh"
    },
    "content": {
      "base": "8vh",
      "sm": "14vh"
    },
    "spacer": {
      "base": "8vh",
      "sm": "19vh"
    },
    "hero": {
      "base": "92vh",
      "sm": "81vh",
      "md": "60vh"
    }
  },
  "topNavSlideOutFeaturedCategories": 3,
  "socialMediaItems": [
    {
      "label": "Facebook",
      "icon": "FACEBOOK",
      "href": "https://www.facebook.com/vinlagring/?view_public_for=104695747628337"
    },
    {
      "label": "Instagram",
      "icon": "INSTAGRAM",
      "href": "https://www.instagram.com/vinlagring/?hl=sv"
    }
  ],
  "topNavPanel": {
    "items": [
      {
        "icon": "FaCrown",
        "label": "Om oss",
        "href": "/about-us"
      },
      {
        "icon": "MdOutlineLocalShipping",
        "label": "Fri frakt över 999 kr"
      },
      {
        "icon": "FaCrown",
        "label": "VÅRA SHOWROOM",
        "href": "/showrooms"
      }
    ]
  }
};
export const serverTheme = {}; 
